<template>
    <div>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
             <jy-query-item label="工单编号" prop="wOrderId">
                <el-input v-model="form.wOrderId" />
            </jy-query-item>
            <jy-query-item label="分拣中心" prop="tUnitId">
                <el-select v-model="form.tUnitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
             <jy-query-item label="地磅名称" prop="wbName">
                <el-input v-model="form.wbName" />
            </jy-query-item>
            <jy-query-item label="产废企业" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in rUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="固废类别" prop="wasteTId">
                <el-select v-model="form.wasteTId">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="接收日期" prop="receiveT" :span="1.5">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.receiveT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesStockManageCenterRecordList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesStockManageCenterRecordReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" @click="add" v-if="btnexist('wastesStockManageCenterRecordInsert')">新增</el-button>
                    <export-excel
                        v-if="btnexist('wastesStockManageCenterRecordExport') && false"
                        baseUrl="/iwastes-admin"
                        url="/schedule/record/export"
                        :params="form"
                        fileName="接收记录"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div>
            搜索结果合计：记录{{ total }}条，毛重{{ result.grossW }}{{ unitName }}，皮重{{ result.tareW }}{{ unitName }}，清运总重量{{
                formatWeight(result.grossW, result.tareW)
            }}{{ unitName }}
        </div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="tableData">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                 <jy-table-column label="工单编号" prop="wOrderId" min-width="140"></jy-table-column>
                <jy-table-column label="分拣中心" min-width="140">
                    <template v-slot="scope">{{ formatUnit(scope.row.tUnitId) }}</template>
                </jy-table-column>
                <jy-table-column label="产废企业"  min-width="140">
                    <template v-slot="scope">{{ formatRUnit(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="地磅名称" min-width="140">
                    <template v-slot="scope">{{ formWeighBridge(scope.row.weighBridge)}}</template>
                </jy-table-column>
                <jy-table-column label="固废类别" min-width="120">
                    <template v-slot="scope">{{ formatWasteType(scope.row.wasteTId) }}</template>
                </jy-table-column>
                <jy-table-column label="毛重过磅时间" min-width="160" prop="grossWT"></jy-table-column>
                <jy-table-column label="毛重" min-width="120">
                    <template v-slot="scope">{{ scope.row.grossW }}{{ scope.row.unit }}</template>
                </jy-table-column>
                <jy-table-column label="皮重过磅时间"  min-width="160" prop="tareWT"></jy-table-column>
                <jy-table-column label="皮重" min-width="120">
                    <template v-slot="scope">{{ scope.row.tareW }}{{ scope.row.tareW?scope.row.unit:'-' }}</template>
                </jy-table-column>
                <jy-table-column label="清运总重量" min-width="120">
                    <template v-slot="scope">{{ formatWeight(scope.row.grossW, scope.row.tareW) }}{{ scope.row.unit }}</template>
                </jy-table-column>
                <jy-table-column label="毛重照片" prop="grossWUrl" min-width="140">
                    <template v-slot="scope">
                        <com-img :src="scope.row.grossWUrl" class="cell-img"/>
                    </template>
                </jy-table-column>
                <jy-table-column label="皮重照片" prop="tareWUrl" min-width="140">
                    <template v-slot="scope">
                        <com-img :src="scope.row.tareWUrl" class="cell-img"/>
                    </template>
                </jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <add-update ref="addUpdate" @update="getList"></add-update>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import AddUpdate from "@/components/pages/admin/manageCenter/record/AddUpdate.vue";
    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [btnMixins],
        methods: {
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                this.$http
                    .post("/wasteOrder/pageQuery", {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        ...this.form,
                        grossWTStart: this.$util.addTime(this.form.receiveT && this.form.receiveT[0], true),
                        grossWTEnd: this.$util.addTime(this.form.receiveT && this.form.receiveT[1]),
                        status: "5",
                        dType: "1",
                        receiveT: undefined
                    })
                    .then(res => {
                        this.tableData = res.detail.list;
                        this.tableData.forEach(v => {
                            const w = this.wasteTypes.find(w => w.wasteTId === v.wasteTId);
                            this.$set(v, "unit", w ? w.unit : "");
                        });
                        this.total = res.detail.total;

                        this.initResult();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            add() {
                this.$refs.addUpdate.init();
            },
            // 重置
            resetForm(formName) {
                const wasteTId = this.form.wasteTId;
                this.$refs[formName].resetFields();
                this.form.wasteTId = wasteTId;
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            formatUnit(key) {
                const u = this.unitList.find(v => v.unitId === key);
                return u ? u.cname : "";
            },
            formatRUnit(key) {
                const u = this.rUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formWeighBridge(weighBridge){
                if (weighBridge) {
                    return weighBridge.cName
                }
                return '-'
            },
            formatWasteType(key) {
                const w = this.wasteTypes.find(v => v.wasteTId === key);
                return w ? w.cName : "-";
            },
            formatWeight(m, p) {
                if (isNaN(m)) {
                    return 0;
                }else if(isNaN(p)){
                    return m;
                } else {
                    let v = m-p
                    if (v%1==0) {
                        return (m - p);
                    }else{
                        return (m - p).toFixed(2);
                    }
                }
            },
            setOperateList() {
                let l = [
                    {
                        name: "企业详情",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return this.btnexist("wastesStockManageCenterRecordViewSch");
                        }
                    }
                ];
                this.operateList = l;
            },
            handleView(row) {
                this.$router.push({
                    name: "rubbishUnit",
                    params: {
                        cName: this.formatUnit(row.unitId)
                    }
                });
            },
            initResult() {
                this.$http
                    .post("/wasteOrder/sumReceive", {
                        ...this.form,
                        grossWTStart: this.form.receiveT[0],
                        grossWTEnd: this.form.receiveT[1],
                        status: "5",
                        dType: "1",
                        receiveT: undefined
                    })
                    .then(res => {
                        this.result = res.detail;
                    });
            }
        },
        computed: {
            rUnitList() {
                return this.$parent.rUnitList;
            },
            unitList() {
                return this.$parent.unitList;
            },
            wasteTypes() {
                return this.$parent.wasteTypes;
            },
            unitName() {
                if (this.form.wasteTId && this.wasteTypes) {
                    const one = this.wasteTypes.find(v => v.wasteTId === this.form.wasteTId);
                    return one ? one.unit : "kg";
                }
                return "kg";
            }
        },
        created() {
            this.setOperateList();
        },
        data() {
            return {
                loading: false,
                tableData: [],
                total: 0,

                result: {},

                form: {
                    unitId: "",
                    tUnitId: "",
                    wasteTId: "",
                    receiveT: "",
                    wOrderId: '',
                    wbName: ''
                },

                pageIndex: 1,
                pageSize: 10,
                operateList: [],
                btnMenuId: "wastesStockManageCenterRecord"
            };
        },
        components: {
            ExportExcel,
            AddUpdate
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 15px;
    }

    .cell-img {
        width: 60px !important;
        height: 60px !important;
        margin: 0 auto;
    }
</style>
