<template>
    <div>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="分拣中心" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="调度单号" prop="scheduleId">
                <el-input v-model="form.scheduleId"></el-input>
            </jy-query-item>
            <jy-query-item label="固废类别" prop="wasteTId">
                <el-select v-model="form.wasteTId">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="运输类型" prop="transportT">
                <el-select v-model="form.transportT">
                    <el-option label="整车" value="0"></el-option>
                    <el-option label="散货" value="1"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车牌号" prop="plateNum">
                <el-input v-model="form.plateNum" prop="plateNum"></el-input>
            </jy-query-item>
            <jy-query-item label="司机" prop="driverNa">
                <el-input v-model="form.driverNa" prop="driverNa"></el-input>
            </jy-query-item>
            <jy-query-item label="接收日期" prop="receiveT" :span="1.5">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.receiveT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesStockManageCenterRecordList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesStockManageCenterRecordReset')">重置</el-button>
                <el-row>
                    <export-excel
                        v-if="btnexist('wastesStockManageCenterRecordExport') && false"
                        baseUrl="/iwastes-admin"
                        url="/schedule/record/export"
                        :params="form"
                        fileName="接收记录"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                    <!-- <el-button size="small" type="primary" @click="showList">自定义列表</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <div>
            搜索结果合计：记录{{ result.count || 0 }}条，毛重{{ result.grossW || 0 }}{{ unitName || '' }}，皮重{{ result.tareW || 0 }}{{ unitName || '' }}，清运总重量{{
                formatWeight(result.grossW, result.tareW) || 0
            }}{{ unitName || '' }}
        </div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column label="分拣中心" prop="unitId" v-if="columnProps.includes('unitId')" min-width="130">
                    <template v-slot="scope">{{ formatUnit(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="调度单号" prop="scheduleId" v-if="columnProps.includes('scheduleId')" min-width="150"></jy-table-column>
                <jy-table-column label="固废类别" prop="wasteTId" v-if="columnProps.includes('wasteTId')" min-width="120">
                    <template v-slot="scope">{{ formatWasteType(scope.row.orderList) }}</template>
                </jy-table-column>
                <jy-table-column label="运输类型" prop="transportT" v-if="columnProps.includes('transportT')">
                    <template v-slot="scope">{{ scope.row.transportT === "1" ? "散货" : "整车" }}</template>
                </jy-table-column>
                <jy-table-column label="毛重过磅时间" width="160" prop="grossWT" v-if="columnProps.includes('grossWT')"></jy-table-column>
                <jy-table-column label="毛重" prop="grossW" v-if="columnProps.includes('grossW')" min-width="120">
                    <template v-slot="scope">{{ scope.row.grossW }}{{ scope.row.unit }}</template>
                </jy-table-column>
                <jy-table-column label="皮重过磅时间" width="160" prop="tareWT" v-if="columnProps.includes('tareWT')"></jy-table-column>
                <jy-table-column label="皮重" prop="tareW" v-if="columnProps.includes('tareW')"  min-width="120">
                    <template v-slot="scope">{{ scope.row.tareW }}{{ scope.row.unit }}</template>
                </jy-table-column>
                <jy-table-column label="清运总重量" width="150" prop="weight" v-if="columnProps.includes('weight')">
                    <template v-slot="scope">{{ formatWeight(scope.row.grossW, scope.row.tareW) }}{{ scope.row.unit }}</template>
                </jy-table-column>
                <jy-table-column label="实际总净重量" width="150" prop="rWeight" v-if="columnProps.includes('rWeight')">
                    <template v-slot="scope">{{ formatRWeight(scope.row.orderList) }}</template>
                </jy-table-column>
                <jy-table-column label="偏差值" prop="deviation" v-if="columnProps.includes('deviation')">
                    <template #header>
                        <span>偏差值</span>
                        <el-tooltip class="item" effect="dark" content="偏差值=|实际总净重-清运总重量| / 清运总重" placement="top-start">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </template>
                    <template v-slot="scope">{{ formatDeviation(scope.row) }}</template>
                </jy-table-column>
                <jy-table-column label="毛重照片" prop="grossWUrl" v-if="columnProps.includes('grossWUrl')" min-width="130">
                    <template v-slot="scope">
                        <el-image :src="scope.row.grossWUrl" fit="contain" style="width:100px;height: 100px;"></el-image>
                        <!-- <img :src="scope.row.grossWUrl" /> -->
                    </template>
                </jy-table-column>
                <jy-table-column label="皮重照片" prop="tareWUrl" v-if="columnProps.includes('tareWUrl')" min-width="130">
                    <template v-slot="scope">
                        <el-image :src="scope.row.tareWUrl" fit="contain" style="width:100px;height: 100px;"></el-image>
                        <!-- <img :src="scope.row.tareWUrl" /> -->
                    </template>
                </jy-table-column>
                <jy-table-column label="车牌号" prop="plateNum" v-if="columnProps.includes('plateNum')" min-width="130">
                    <template v-slot="scope">{{ scope.row.vehicleInfo.plateNum }}</template>
                </jy-table-column>
                <jy-table-column label="司机" prop="driverNa" v-if="columnProps.includes('driverNa')"  min-width="130">
                    <template v-slot="scope">{{ formatDrivers(scope.row.driverList) }}</template>
                </jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <t-fixed ref="tFixed" tagTitle="label"></t-fixed>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import { btnMixins } from "@/common/js/button.mixin";
    import TFixed from "@/components/pages/admin/common/TFixed/tFixed.vue";
    export default {
        mixins: [btnMixins],
        methods: {
            showList() {
                this.$refs.tFixed.init(this.columns);
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                this.$http
                    .post("/schedule/pageQuery", {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        ...this.form,
                        fTimeStart: this.$util.addTime(this.form.receiveT && this.form.receiveT[0], true),
                        fTimeEnd: this.$util.addTime(this.form.receiveT && this.form.receiveT[1]),
                        status: "3",
                        dType: "0",
                        receiveT: undefined
                    })
                    .then(res => {
                        this.dataList = res.detail.list;
                        this.dataList.forEach(v => {
                            const w = this.wasteTypes.find(w => w.wasteTId === v.wasteTId);
                            this.$set(v, "unit", w ? w.unit : "");
                        });
                        this.total = res.detail.total;
                        this.initResult();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            add() {
                this.$refs.addUpdate.init();
            },
            // 重置
            resetForm(formName) {
                const wasteTId = this.form.wasteTId;
                this.$refs[formName].resetFields();
                this.form.wasteTId = wasteTId;
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            formatUnit(key) {
                const u = this.unitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatWasteType(list) {
                if (!list || !list.length) {
                    return '-'
                }
                let str = ''
                list.forEach(one=>{
                    const w = this.wasteTypes.find(v => v.wasteTId === one.wasteTId);
                    if (w) {
                        str += w.cName
                    }
                })
                return str;
            },
            formatDrivers(list) {
                if (list && list.length) {
                    return list.map(v => v.cName).join(",");
                } else {
                    return "-";
                }
            },
            formatRWeight(list) {
                if (list && list.length) {
                    return list.reduce((total, cur) => {
                        return total + cur.rWeight||0;
                    }, 0);
                } else {
                    return "-";
                }
            },
            formatWeight(m, p) {
                if (isNaN(m)) {
                    return 0;
                }else if(isNaN(p)){
                    return m;
                } else {
                    let v = m-p
                    if (v%1==0) {
                        return (m - p);
                    }else{
                        return (m - p).toFixed(2);
                    }
                }
            },
            formatDeviation(row) {
                let rWeight = 0;
                const list = row.orderList;
                if (list && list.length) {
                    rWeight = list.reduce((total, cur) => {
                        return total + cur.rWeight;
                    }, 0);
                }

                const weight = this.formatWeight(row.grossW, row.tareW);

                if (weight === 0) {
                    return "100%";
                } else {
                    return Math.floor((Math.abs(rWeight - weight) / weight) * 100) + "%";
                }
            },
            setOperateList() {
                let l = [
                    {
                        name: "查看调度单",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return this.btnexist("wastesStockManageCenterRecordViewSch");
                        }
                    }
                ];
                this.operateList = l;
            },
            handleView(row) {
                this.$router.push({
                    name: "dOrder",
                    params: {
                        scheduleId: row.scheduleId
                    }
                });
            },
            initResult() {
                this.$http
                    .post("/receive/countReceive", {
                        ...this.form,
                        fTimeStart: this.form.receiveT[0],
                        fTimeEnd: this.form.receiveT[1],
                        status: "3",
                        dType: "0",
                        receiveT: undefined
                    })
                    .then(res => {
                        this.result = res.detail;
                    });
            }
        },
        computed: {
            unitList() {
                return this.$parent.unitList;
            },
            wasteTypes() {
                return this.$parent.wasteTypes;
            },
            unitName() {
                if (this.form.wasteTId && this.wasteTypes) {
                    const one = this.wasteTypes.find(v => v.wasteTId === this.form.wasteTId);
                    return one ? one.unit : "kg";
                }
                return "kg";
            }
        },
        created() {
            this.setOperateList();
        },
        mounted() {
            const columns = this.$refs.tFixed.showList;
            this.columnProps = columns.length ? columns : this.columns.map(v => v.prop);
        },
        data() {
            return {
                loading: false,
                dataList: [],
                total: 0,

                result: {},

                form: {
                    unitId: "",
                    scheduleId: "",
                    transportT: "",
                    plateNum: "",
                    driverNa: "",
                    wasteTId: "",
                    receiveT: []
                },

                pageIndex: 1,
                pageSize: 10,
                operateList: [],
                btnMenuId: "wastesStockManageCenterRecord",

                columns: [
                    { label: "分拣中心", prop: "unitId" },
                    { label: "调度单号", prop: "scheduleId" },
                    { label: "固废类别", prop: "wasteTId" },
                    { label: "运输类型", prop: "transportT" },
                    { label: "毛重过磅时间", prop: "grossWT" },
                    { label: "毛重", prop: "grossW" },
                    { label: "皮重过磅时间", prop: "tareWT" },
                    { label: "皮重", prop: "tareW" },
                    { label: "清运总重量", prop: "weight" },
                    { label: "实际总净重量", prop: "rWeight" },
                    { label: "偏差值", prop: "deviation" },
                    { label: "毛重照片", prop: "grossWUrl" },
                    { label: "皮重照片", prop: "tareWUrl" },
                    { label: "车牌号", prop: "plateNum" },
                    { label: "司机", prop: "driverNa" }
                ],
                columnProps: []
            };
        },
        components: {
            ExportExcel,
            TFixed
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 15px;
    }
</style>
