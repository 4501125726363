<template>
    <div>
        <el-radio-group v-model="current" size="mini" @change="change">
            <el-radio-button v-for="r in countList" :key="r.status" :label="r.status">{{ r.statusStr }}（{{ r.count }}）</el-radio-button>
        </el-radio-group>
        <transition>
            <component :is="page" ref="page"></component>
        </transition>
    </div>
</template>

<script>
    import env from "./environmentService.vue";
    import org from "./orgTransport.vue";
    export default {
        methods: {
            change() {
                if (this.current === "0") {
                    this.page = "env";
                } else {
                    this.page = "org";
                }
                this.$nextTick(() => {
                    const childVm = this.$refs.page;
                    if (!childVm.form.wasteTId) {
                        childVm.form.wasteTId = this.wasteTypes[0].wasteTId;
                    }
                    childVm.getList();
                });
            }
        },
        data() {
            return {
                current: "0",
                page: "env",

                countList: [],
                unitList: [],
                rUnitList: [],
                wasteTypes: []
            };
        },
        async created() {
            let p1 = this.$http.post("/receive/countList"),
                p2 = this.$http.post("/unitInfo/queryUnitList", {
                    cName: "",
                    unitType: "3"
                }),
                p3 = this.$http.post("/wasteType/listAll"),
                p4 = this.$http.post("/unitInfo/queryUnitList", {
                    cName: "",
                    unitType: "0"
                });
            Promise.all([p1, p2, p3, p4])
                .then(res => {
                    const [r1, r2, r3, r4] = res;
                    this.countList = r1.detail;
                    this.unitList = r2.detail;
                    this.wasteTypes = r3.detail;
                    this.rUnitList = r4.detail;
                })
                .then(() => {
                    const childVm = this.$refs.page;
                    if (childVm && childVm.form) {
                        childVm.form.wasteTId = this.wasteTypes[0].wasteTId;
                        childVm.getList();
                    }
                });
        },
        components: {
            env,
            org
        }
    };
</script>
<style></style>
