<template>
    <el-dialog :visible.sync="visible" title="新增接收记录" @close="close">
        <el-form ref="recordForm" size="small" :model="model" :rules="rules" label-width="120px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="分拣中心" prop="tUnitId">
                        <el-select v-model="model.tUnitId" @change="changeUnit">
                            <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="产废企业" prop="unitId">
                        <el-select v-model="model.unitId">
                            <el-option v-for="u in rUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="固废类别" prop="wasteTId">
                <el-radio-group v-model="model.wasteTId" @change="changeType">
                    <el-radio-button v-for="t in wasteTypes" :key="t.wasteTId" :label="t.wasteTId">{{ t.cName }}</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="毛重" prop="grossW">
                        <el-input v-model="model.grossW">
                            <template #append>{{ unitName }}</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="皮重" prop="tareW">
                        <el-input v-model="model.tareW">
                            <template #append>{{ unitName }}</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="毛重过磅时间" prop="grossWT">
                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="model.grossWT"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="皮重过磅时间" prop="tareWT">
                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="model.tareWT"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="毛重过磅照片" prop="grossWUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleGrossWUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.grossWUrl" :src="model.grossWUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="皮重过磅照片" prop="tareWUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleTareWUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.tareWUrl" :src="model.tareWUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div class="handle_form">
            <el-button v-loading="loading" size="small" type="primary" @click="makeSure">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        created() {
            this.$http
                .post("/unitInfo/queryUnitList", {
                    cName: "",
                    unitType: "0",
                    contractWorkT: this.$util.parseTime(new Date(), "yyyy-MM-dd hh:mm:ss")
                })
                .then(res => {
                    this.rUnitList = res.detail;
                });
        },
        methods: {
            changeType(t) {
                const type = this.wasteTypes.find(v => v.wasteTId === t);
                this.unitName = type.unit;
            },
            close() {
                this.$refs.recordForm.resetFields();
            },
            init() {
                this.visible = true;
            },
            handleGrossWUrlAvatarSuccess({ detail }) {
                this.model.grossWUrl = detail;
            },
            handleTareWUrlAvatarSuccess({ detail }) {
                this.model.tareWUrl = detail;
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || file.type === "image/png";

                if (!isJPG) {
                    this.$message.error("图片只能是JPG或PNG格式");
                }
                return isJPG;
            },
            setUploadHeader() {
                let header = {
                    token: this.$util.getStor("token")
                };
                this.uploadHeader = header;
            },
            makeSure() {
                this.$refs.recordForm.validate(valide => {
                    if (valide) {
                        this.loading = true;
                        let url = "/receive/add";
                        if (this.model.scheduleId) {
                            url = "/receive/update";
                        }
                        this.$http
                            .post(url, this.model)
                            .then(res => {
                                this.$message.success({
                                    message: res.msg
                                });
                                this.visible = false;
                                this.$emit("update");
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                });
            },
            changeUnit(key) {
                const u = this.unitList.find(v => v.unitId === key);
                this.model.orgId = u.orgId;
                this.model.mOrgId = u.morgId;
                this.model.addr = u.addr;
            }
        },
        mounted() {
            this.setUploadHeader();
        },
        data() {
            return {
                visible: false,
                loading: false,

                unitName: "kg",

                model: {
                    tUnitId: "",
                    unitId: "",
                    wasteTId: "",
                    grossW: "",
                    tareW: "",
                    grossWT: "",
                    tareWT: "",
                    grossWUrl: "",
                    tareWUrl: ""
                },
                rules: {
                    tUnitId: [{ required: true, message: "分拣中心不为空", trigger: "change" }],
                    unitId: [{ required: true, message: "产废企业不为空", trigger: "change" }],
                    wasteTId: [{ required: true, message: "固废类别不为空", trigger: "change" }],
                    grossW: [{ required: true, message: "毛重不为空", trigger: "blur" }],
                    tareW: [{ required: true, message: "皮重不为空", trigger: "blur" }],
                    grossWT: [{ required: true, message: "毛重过磅时间不为空", trigger: "change" }],
                    tareWT: [{ required: true, message: "皮重过磅时间不为空", trigger: "change" }],
                    grossWUrl: [{ required: true, message: "毛重过磅照片不为空", trigger: "change" }],
                    tareWUrl: [{ required: true, message: "皮重过磅照片不为空", trigger: "change" }]
                },
                uploadImgUrl: "/iwastes-admin/sys/user/uploadLogo",
                uploadHeader: {},
                rUnitList: []
            };
        },
        computed: {
            unitList() {
                return this.$parent.unitList;
            },
            wasteTypes() {
                return this.$parent.wasteTypes;
            }
        }
    };
</script>
<style scoped>
    .handle_form {
        text-align: center;
    }
</style>
